'use strict';
const THREE = require('three');
import * as Helpers from '../helpers.js';
import * as Config from '../config.js';

export function Core(importedMesh) {
	THREE.Group.call( this );
	this.isCore = true; 
	this.type = 'Core';
	this.name = importedMesh.name;
	this.coreType = this.name.split("_")[1];
	this.mesh = importedMesh,
	this.selected = false;
	var cameraZoom, 
		cameraZoomTarget;

	this.visible = false;
	this.mesh.castShadow = false;
	this.mesh.material = Config.materials.core;
	this.attach(importedMesh);

	importedMesh.geometry.computeBoundingBox();
	this.iconPosition = new THREE.Vector3(importedMesh.geometry.boundingSphere.center.x, importedMesh.geometry.boundingBox.max.y + Config.iconHover.core, importedMesh.geometry.boundingSphere.center.z);
}
Core.prototype = Object.assign( Object.create( THREE.Group.prototype ), { // not sure why this is necessary, but it was like that here: https://github.com/mrdoob/three.js/blob/dev/src/objects/Group.js
	constructor: Core,
	isCore: true
} );
Core.prototype.hoverStart = function() {
	if (!this.selected){
		this.hoverSameTypeStart(this.coreType);
	}
};
/*Core.prototype.existingType = function() {
	for (var i = 0; i < this.parent.cores.length; i++) {
		if (this.type == this.parent.cores[i].coreType){
			return this.parent.cores[i];			
		}
	}
	return {};
};*/
Core.prototype.hoverSameTypeStart = function(type) {
	if (this.parent.cores){
	for (var i = 0; i < this.parent.cores.length; i++) {
		if ( !this.selected && (type == this.parent.cores[i].coreType) ){
			this.parent.cores[i].mesh.material = Config.materials.hoveredCore;
			this.parent.cores[i].icon.visible = false;
			this.parent.cores[i].iconSelected.visible = true;
		}
	}
	}
};
Core.prototype.hoverEnd = function() {
	if (!this.selected){
		this.hoverSameTypeEnd(this.coreType);
	}
};
Core.prototype.hoverSameTypeEnd = function(type) {
	if (this && this.parent && this.parent.cores){
		for (var i = 0; i < this.parent.cores.length; i++) {
			if ( !this.selected && (type == this.parent.cores[i].coreType) ){
				this.parent.cores[i].mesh.material = Config.materials.core;
				this.parent.cores[i].icon.visible = true;
				this.parent.cores[i].iconSelected.visible = false;
			}
		}
	}
};
Core.prototype.deselectPrevious = function() {
	this.selected = false;
	this.mesh.material = Config.materials.core;
	this.icon.visible = true;
	this.iconSelected.visible = false;	
};
Core.prototype.deselectSameType = function(type) {
	if (this.parent.cores){
		for (var i = 0; i < this.parent.cores.length; i++) {
			if (type == this.parent.cores[i].coreType){
				this.parent.cores[i].selected = false;
				this.parent.cores[i].mesh.material = Config.materials.core;
				this.parent.cores[i].icon.visible = true;
				this.parent.cores[i].iconSelected.visible = false;
			}
		}
	}
};
/*
Core.prototype.select = function() {
	this.selected = true;
	this.mesh.material = Config.materials.selectedCore;
};
*/
Core.prototype.selectSameType = function(type) {
	if (this.parent.cores){
		for (var i = 0; i < this.parent.cores.length; i++) {
			if (type == this.parent.cores[i].coreType){
				this.parent.cores[i].selected = true;
				this.parent.cores[i].mesh.material = Config.materials.selectedCore;
				this.parent.cores[i].icon.visible = false;
				this.parent.cores[i].iconSelected.visible = false;
			}
		}
	}
};
Core.prototype.cameraJumpTo = function() {
	var newCameraPos = new THREE.Vector3(
		this.mesh.geometry.boundingSphere.center.x,
		this.mesh.geometry.boundingSphere.center.y + 50,
		this.mesh.geometry.boundingSphere.center.z + 100);
	var newControlsTarget = this.mesh.geometry.boundingSphere.center;
	Helpers.cameraJumpTo(newCameraPos,newControlsTarget);
};
Core.prototype.cameraTweenTo = function() {
	var newCameraPos = new THREE.Vector3(
		this.mesh.geometry.boundingSphere.center.x,
		this.mesh.geometry.boundingSphere.center.y + 10,
		this.mesh.geometry.boundingSphere.center.z + 50);
	var newControlsTarget = this.mesh.geometry.boundingSphere.center;
	
	Helpers.cameraTweenTo(newCameraPos,newControlsTarget);
};