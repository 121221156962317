'use strict';
const THREE = require('three');
import TWEEN from '@tweenjs/tween.js';

import { camera, controls } from './scene.js';
import { cameraPosition, targetPosition} from './config.js';

export function Helpers() {
}

var getEdges = function(mesh, edgeMaterial){
	var edges = new THREE.EdgesGeometry( mesh.geometry );
	var line = new THREE.LineSegments( edges, edgeMaterial);
	line.name=mesh.name+"_Edge";
	return line;
};
var dispose = function(object, deleteMaterials){
	if (object.geometry) object.geometry.dispose();
	// Not sure if it is good to get rid of the materials as well, in case of buildings
	if (deleteMaterials){
		if (object.material.texture) object.material.texture.dispose();
		if (object.material.map) object.material.map.dispose();
		if (object.material) object.material.dispose();
}
	if (object.parent) delete object.parent.object;
};
var cameraJumpTo = function(newCameraPos,newControlsTarget){
	camera.position.set(
		newCameraPos.x,
		newCameraPos.y,
		newCameraPos.z);
	controls.target.set(
		newControlsTarget.x,
		newControlsTarget.y,
		newControlsTarget.z);
};
var cameraTweenTo = function(newCameraPos,newControlsTarget){
	TWEEN.removeAll();
	var tween = new TWEEN.Tween(camera.position)
		.to(newCameraPos, 1700)
		.start();
	
	var tween2 = new TWEEN.Tween(controls.target)
		.to(newControlsTarget, 1700)
		.start();	
};
var cameraTweenReset = function() {
	TWEEN.removeAll();
	var tween = new TWEEN.Tween(camera.position)
		.to(cameraPosition["initial"], 1700)
		.start();
	
	var tween2 = new TWEEN.Tween(controls.target)
		.to(targetPosition["initial"], 1700)
		.start();	
};
var cameraJumpReset = function() {
	camera.position.set(cameraPosition["initial"].x,cameraPosition["initial"].y,cameraPosition["initial"].z);
	controls.target.set(targetPosition["initial"].x,targetPosition["initial"].y,targetPosition["initial"].z);
};
var tweenMeshTo = function(mesh, newPosition) {
	//TWEEN.removeAll();
	var tween = new TWEEN.Tween(mesh.position)
		.to(newPosition, 1600)
		.start();	
};
var getAsgeb = function(stringIn){
	if (stringIn.includes("asgeb")){
		if(stringIn.split("_").length == 1){	// Expecting string like: "asgeb001"
			return stringIn; 
		} else if (stringIn.split("_")[0].includes("asgeb")){ // Expecting string like: "asgeb001_asazg002"
			return stringIn.split("_")[0];
		} else {
			console.log("Not conventional input for helpers.getAsgeb. Returning empty string.");
			return "";
		}
	}
	return "";
};
var getAsazg = function(stringIn){
	if (stringIn.includes("asazg")){
		if(stringIn.split("_").length == 1){	// Expecting string like: "asazg002"
			return stringIn; 
		} else if (stringIn.split("_")[1].includes("asazg")){	// Expecting string like: "asgeb001_asazg002"
			return stringIn.split("_")[1];
		} else {
			console.log("Not conventional input for helpers.getAsazg. Returning empty string.");
			return "";
		}
	}
	return "";
};

export {getEdges, dispose, cameraJumpTo, cameraTweenTo, cameraTweenReset, cameraJumpReset, tweenMeshTo, getAsgeb, getAsazg};