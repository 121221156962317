import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';
import '@fortawesome/fontawesome-free/js/fontawesome';
import '@fortawesome/fontawesome-free/js/solid';
import '@fortawesome/fontawesome-free/js/regular';
import '@fortawesome/fontawesome-free/js/brands';
import $ from "jquery";
import noUiSlider from 'nouislider';
import '../node_modules/nouislider/distribute/nouislider.min.css';
import wNumb from 'wnumb';

import '../public/css/aspn.css';
// @ts-ignore
import * as templates from './templates.ts';
// @ts-ignore
import { start, initSidebar } from './city.ts';

document.body.innerHTML = templates.main();
const headerElement = document.body.querySelector('.aspn-navbar');
const footerElement = document.body.querySelector('.aspn-footer');
export const worldElement =  <HTMLElement>document.body.querySelector('.aspn-3d-world');
export const sidebarElement = document.body.querySelector('.aspn-sidebar');
export let stepData = null;

function initSliders(step, min, max, start) {
    var handlesSlider = document.getElementById('rangeSlider');
    noUiSlider.create(handlesSlider, {
        start: [start],
        step: step,
        tooltips: [true],
        range: {
            'min': [min],
            'max': [max]
        },
        format: wNumb({
            decimals: 0,
            thousand: '.'
        })
    });
}

function toggleIdDetails() {
    var lid = document.getElementById("lId-details");
    if (lid.style.display === "none") {
        lid.style.display = "contents";
    } else {
        lid.style.display = "none";
        var liInfo = document.getElementById("lId-info");
        liInfo.style.display = "none";
    }
}

function toggleInfoOverlay(element) {
    var lidInfo = document.getElementById("lId-info");
    if (lidInfo.style.display === "none") {
        lidInfo.style.display = "block";
        element.currentTarget.children[0].style.color = "white";
    } else {
        lidInfo.style.display = "none";
        element.currentTarget.children[0].style.color = "black";
    }
}

function createNavContext (activePage) {
    const navbar_items = 
    [
        {
            slug:"devis",
            icon:"file-import",
            title:"LiftDevis",
            text:"liftdevis.ch",
            status:""
        },
        {
            slug:"plan",
            icon:"edit",
            title:"LiftPlan",
            text:"liftplan.ch",
            status:""
        },
        {
            slug:"design",
            icon:"swatchbook",
            title:"LiftDesign",
            text:"liftdesign.ch",
            status:""
        }
    ];
    let result = Array.from(navbar_items);
    let devis = navbar_items[0];
    let plan = navbar_items[1];
    let design = navbar_items[2];
    switch (activePage) {
        case "plan": {
            devis.status    = "inactive";
            plan.status     = "active";
            design.status   = "inactive";
            break;
        }
        case "devis":{
            devis.status    = "active";
            plan.status     = "inactive";
            design.status   = "inactive";
            break;
        }
        case "design":{
            devis.status    = "inactive";
            plan.status     = "inactive";
            design.status   = "active";
            break;
        }
        default: {
            devis.status    = "inactive";
            plan.status     = "active";
            design.status   = "inactive";
            break;
        }
    }
    for (let i = 0; i < navbar_items.length; i++) { 
        console.log(navbar_items[i]);
        // devis active
        if(i == 0 && navbar_items[i].status === "active") {
            result.shift();
            result.splice(1,0,navbar_items[i]);
        }
        // design active 
        else if(i == 2 && navbar_items[i].status === "active") {
            result.pop();
            result.splice(1,0,navbar_items[i]);
        }
        // plan active (default) 
        else {} 
    }
    return result;
};

function postData(stepId, stepBody, callback) {
    $.ajax({
        url: '/api/data/steps/' + stepId,
        method: 'POST',
        dataType: 'json',
        contentType: 'application/x-www-form-urlencoded;charset=UTF-8',
        success: callback,
        data: {
            "model": JSON.stringify(stepBody)
        }
    });
}
function uploadData(stepId, stepBody) {
    postData(stepId, stepBody, function(data) {
        if(data) {
            sidebarElement.innerHTML = templates.sidebar(data.body);
            stepData = data;
            initSliders(1, 8, 33, 13);
        }
    });
}

function setupListeners() {
    start(worldElement);
    // Add event listener to table
    let li = document.getElementById("lId");
    li.addEventListener("click", toggleIdDetails, false);   
    let liInfo = document.getElementById("lIdInfo");
    liInfo.addEventListener("click", toggleInfoOverlay, false);    
    $('#idForm').on('submit', function () {
        alert('Form submitted!');
        return false;
    });
} 

/**
 * Use Window location hash to show the specified view. 
 */
const showView = async () => {
    const [view, ...params] = window.location.hash.split('/');
    let navbar_items;
    switch (view) {
        case '#plan': {
            navbar_items = createNavContext("plan");
            headerElement.innerHTML = templates.navbar({navbar_items});
            sidebarElement.innerHTML = templates.sidebar({});
            footerElement.innerHTML = templates.footer();
            
            setupListeners();
           
            $(function () {
                $('[data-toggle="popover"]').popover();
            });
            break;
        }

        case '#design': {
            navbar_items = createNavContext("design");
            headerElement.innerHTML = templates.navbar({navbar_items});
            worldElement.innerHTML = "";
            worldElement.innerHTML = 
            `<iframe src="https://data.raumgleiter.com/3953_Schindler_Konfigurator/SchindlerDesignTool_V1.2.2_20191017/">
                <p>Your browser does not support iframes.</p>
            </iframe>`;
            footerElement.innerHTML = templates.footer();
            break;
        }

        case '#devis' : {
            navbar_items = createNavContext("devis");
            headerElement.innerHTML = templates.navbar({navbar_items});
            worldElement.innerHTML = "";
            footerElement.innerHTML = templates.footer();
            break;
        }

        default:
            // Undefined View
            throw Error(`Unrecognized view: ${view}`);
    }
};

window.addEventListener('hashchange', showView);
showView().catch(err => window.location.hash = '#plan');

export { 
    uploadData 
}; 