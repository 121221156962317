'use strict';
const THREE = require('three');

export var	frustumSize = 1000,
		cameraTargetLimitationOffset = 300,
		cameraLimitations ={
			min: {x: -1000, z: -2600},
			max: {x: 2800, z: 1000}
		},
		skyColor = 0xeef8ff,
		sceneLightColor = 0xaaaaaa,
		sceneLightIntensity = .4,
		ambientLightColor = 0xeeeeee,
		ambientLightIntensity = .95,
		iconHover = {
			building: 45, 
			core: 10
		},
		iconSizeInitial = { 
			building: 30, 
			core: 10
		}, 
		materials = {
			surroundings: new THREE.MeshLambertMaterial( { color: 0xe0e0e0, name:"Context Buildings Material" } ),
			ground : new THREE.MeshLambertMaterial( { color: 0xeeeeee, name:"Context Ground Material" } ),
			coast : new THREE.MeshLambertMaterial( { color: 0xcccccc, name:"Context Coast Material" } ),
			street : new THREE.MeshLambertMaterial( { color: 0xdddddd, name:"Context Street Material" } ),
			rail : new THREE.MeshLambertMaterial( { color: 0xbbbbbb, name:"Context Rail Material" } ),
			tree : new THREE.MeshLambertMaterial( { color: 0x7c9d87 , name:"Context Tree1 Material" } ),
			tree2 : new THREE.MeshLambertMaterial( { color: 0x6c9980 , name:"Context Tree2 Material" } ),
			tree3 : new THREE.MeshLambertMaterial( { color: 0x778887 , name:"Context Tree3 Material" } ),
			grass : new THREE.MeshLambertMaterial( { color: 0xb6c1bb , name:"Context Grass Material" } ),
			water : new THREE.MeshLambertMaterial( { color: 0x80a5b0, name:"Context Water Material" } ),
			streetEdge : new THREE.LineBasicMaterial({color: 0xcccccc, linewidth: 2, name:"Context Street Edge Material" }),
			waterEdge : new THREE.LineBasicMaterial({color: 0xaaaaaa, linewidth: 150, name:"Context Water Edge Material" }),

			building : new THREE.MeshLambertMaterial( { color: 0xeabc7a, name:"Building Default Material" } ),
			buildingHovered : new THREE.MeshLambertMaterial( { color: 0xe9a452, name:"Building Hovered Material" } ),
			//buildingSelected : new THREE.MeshLambertMaterial( { color: 0x999999, transparent: true, opacity: 0.55, name:"Selected Building Material" } ),
			buildingSelectedFront : new THREE.MeshLambertMaterial( { color: 0x999999, transparent: true, opacity: 0.25, name:"Building Selected Front Material" } ),
			buildingSelectedBack : new THREE.MeshLambertMaterial( { color: 0xcdcdcd, side: THREE.DoubleSide, name:"Building Selected Back Material" } ),
			buildingSelectedFloor : new THREE.LineBasicMaterial({color: 0x999999, linewidth: 1, name:"Building Selected Floor Edge Material" }),

			core : new THREE.MeshLambertMaterial( { color: 0xcc5555, name:"Core Default Material" } ),
			hoveredCore : new THREE.MeshLambertMaterial( { color: 0xff0000, name:"Core Hovered Material" } ),
			selectedCore : new THREE.MeshLambertMaterial( { color: 0xff3300, name:"Core Selected Material" } ), 

			eleWallEdge : new THREE.LineBasicMaterial({color: 0x888888, linewidth: 3,  name:"Elevator Wall Edge Material" }),
			eleDoorEdge : new THREE.LineBasicMaterial({color: 0x660000, linewidth: 3, name:"Elevator Door Edge Material" }),
			eleOtherEdge : new THREE.LineBasicMaterial({color: 0x222222, linewidth: 3, name:"Elevator Other Edge Material" }),
			eleFrameMat : new THREE.MeshLambertMaterial( { color: 0xaa0000, name:"Elevator Door Frame Material" } ),
			eleDoorOutMat : new THREE.MeshLambertMaterial( { color: 0xaa0000, name:"Elevator Door Out Material" } ),
			eleDoorInMat : new THREE.MeshLambertMaterial( { color: 0xbbbbbb, name:"Elevator Door In Material" } ),
			eleCabinMat : new THREE.MeshLambertMaterial( { color: 0x555555, reflectivity: .5, name:"Elevator Cabin Material" } ),
			eleTechnoMat : new THREE.MeshLambertMaterial( { color: 0x333333, name:"Elevator Techno Material" } ),
			eleWallMat : new THREE.MeshLambertMaterial( { color: 0x555555, transparent: true, opacity: 0.5, name:"Elevator Wall Material" } )
		}, 
		cameraPosition = {
			default		:	new THREE.Vector3(	736,74,-554	),
			initial		:	new THREE.Vector3(	175,435,145	),
			elevator	:	new THREE.Vector3(	485, 485,285	),
			asgeb001	:	new THREE.Vector3(	466,60,-294	),
			asgeb002	:	new THREE.Vector3(	1292,62,-881	),
			asgeb003	:	new THREE.Vector3(	589,105,-836	),
			asgeb004	:	new THREE.Vector3(	196,73,-410	),
			asgeb005	:	new THREE.Vector3(	771,68,-808	),
			asgeb006	:	new THREE.Vector3(	736,74,-554	),
			asgeb007	:	new THREE.Vector3(	779,57,-196	),
			asgeb008	:	new THREE.Vector3(	1426,121,-407	),
			asgeb009	:	new THREE.Vector3(	1150,63,-413	),
			asgeb010	:	new THREE.Vector3(	715,63,-378	),
			asgeb011	:	new THREE.Vector3(	343,101,-412	),
			asgeb012	:	new THREE.Vector3(	963,87,-571	),
			asgeb013	:	new THREE.Vector3(	1123,63,-585	),
			asgeb014	:	new THREE.Vector3(	225,83,-713	),
			asgeb015	:	new THREE.Vector3(	593,24,-138	), 		
			asgeb016	:	new THREE.Vector3(	736,74,-554	)
		}, 
		targetPosition = {
			default		:	new THREE.Vector3(	736,18,-665	),
			initial		:	new THREE.Vector3(	680,0,-560	), 
			elevator	:	new THREE.Vector3(	0,150,0	),
			asgeb001	:	new THREE.Vector3(	466,10,-394	),
			asgeb002	:	new THREE.Vector3(	1344,14,-966	),
			asgeb003	:	new THREE.Vector3(	600,49,-1013	),
			asgeb004	:	new THREE.Vector3(	254,8,-525	),
			asgeb005	:	new THREE.Vector3(	771,18,-908	),
			asgeb006	:	new THREE.Vector3(	736,18,-665	),
			asgeb007	:	new THREE.Vector3(	824,15,-290	),
			asgeb008	:	new THREE.Vector3(	1570,31,-561	),
			asgeb009	:	new THREE.Vector3(	1233,9,-466	),
			asgeb010	:	new THREE.Vector3(	715,13,-478	),
			asgeb011	:	new THREE.Vector3(	408,20,-579	),
			asgeb012	:	new THREE.Vector3(	963,12,-721	),
			asgeb013	:	new THREE.Vector3(	1198,14,-662	),
			asgeb014	:	new THREE.Vector3(	262,13,-882	),
			asgeb015	:	new THREE.Vector3(	634,2,-184	),
			asgeb016	:	new THREE.Vector3(	736,18,-665	),
		},
		modelsPath = "https://as-schindler-pn.s3.eu-central-1.amazonaws.com/";
