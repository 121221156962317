'use strict';
const THREE = require('three');
import * as config from '../config.js';
import { scene } from '../scene.js';
import { getElevatorID } from '../../city.ts';
import * as helpers from '../helpers.js';

export function Elevator(elevatorSource) {
	THREE.Group.call( this );
	this.isElevator = true; 
	this.type = 'Elevator';
	this.sourceBuilding = "";
	this.sourceCore = "";
	this.name = elevatorSource; //elevatorSource.includes("asazg") ?  getElevatorID(this.sourceCore) : elevatorSource;
	this.filePath = config.modelsPath + 'elevators/' + this.name + '.gltf';
	this.loadedGeometry;
	this.doorPanels = [];
	this.doorsOpen = false;
}
Elevator.prototype = Object.assign( Object.create( THREE.Group.prototype ), { // not sure why this is necessary, but it was like that here: https://github.com/mrdoob/three.js/blob/dev/src/objects/Group.js
	constructor: Elevator,
	isElevator: true
} );

Elevator.prototype.setSource = function(elevatorSource){
	this.sourceBuilding = helpers.getAsgeb(elevatorSource);
	this.sourceCore = helpers.getAsazg(elevatorSource);
};
Elevator.prototype.removeSource = function(){
	this.sourceBuilding = "";
	this.sourceCore = "";
};
Elevator.prototype.loadModel = function() {
	var loader = new THREE.GLTFLoader(),
		lines,
		elevator=this;
	loader.load(
		elevator.filePath, 
		function ( gltf ) {
			gltf.scene.traverse( function ( child ) {
				if ( child.isMesh ) {
					child.visible=true;
					var meshID = child.name.split("_");
					elevator.applyLiftMaterials(child, meshID);
					if (  (meshID.length == 3) && 
							( meshID[0].startsWith("L") || meshID[0].startsWith("C") )
						){
						elevator.prepareLiftDoors(child);
						child.isDoor = true;
						lines = helpers.getEdges(child, 
							meshID[0].startsWith("L") ? config.materials.eleDoorEdge : config.materials.eleOtherEdge);
						child.attach( lines);
					}
					if ( child.name == "Shaft" ) {
						lines = helpers.getEdges(child, config.materials.eleWallEdge);
						gltf.scene.attach( lines);
					} else if ( !child.isDoor ){
						lines = helpers.getEdges(child, config.materials.eleOtherEdge);
						gltf.scene.attach( lines);
					}
				}
			} );
			gltf.scene.name = elevator.name;
			scene.add( gltf.scene );
			elevator.loadedGeometry = gltf.scene;
			elevator.filePath = elevator.filePath;
			scene.elevators.push(elevator);
		},

		function ( xhr ) {
			console.log( ( xhr.loaded / xhr.total * 100 ) + '% loaded' );
		},
		function ( error ) {
			console.log(error);
			console.log( 'An error happened' );
		}

	);
};

Elevator.prototype.applyLiftMaterials = function(mesh, meshID) {	
	if ( mesh.name.startsWith("Frame_n") ){
		mesh.material = config.materials.eleFrameMat;

	} else if ( (meshID.length == 3) && (meshID[0].startsWith("L")) &&  (meshID[2] == "C") ) {
		mesh.material = config.materials.eleDoorOutMat;
	} else if ( (mesh.name.startsWith("Rail")) ||  (mesh.name.startsWith("Holder")) ){  
		mesh.material = config.materials.eleTechnoMat;

	} else if ( (meshID.length == 3) && (meshID[0].startsWith("C")) &&  (meshID[2] == "C") ) {
		mesh.material = config.materials.eleDoorInMat;

	} else if ( mesh.name.startsWith("Shaft") ){
		mesh.material = config.materials.eleWallMat;

	} else if ( (meshID.length == 3) && (meshID[2] == "O") ) {	// These are the helper-positions of doors when they are open. 
		mesh.visible = false;
		
	} else {
		mesh.material = config.materials.eleCabinMat;
	}
};
Elevator.prototype.prepareLiftDoors = function(mesh) {
	var panelRootName = mesh.name.slice(0,5),
		existingPanelIndex = -1;

	// Check if a panel with such root-name already exists within this elevator. 
	for (var i = 0; i < this.doorPanels.length; i++) {
		if (this.doorPanels[i].name.startsWith(panelRootName)){
			existingPanelIndex = i;
		} 
	}
	mesh.geometry.computeBoundingSphere();
	if (existingPanelIndex < 0) { 					// No panel with such name yet. Create a new object:
		if ( mesh.name.endsWith("O") ){				// Mesh is helper (door in open state)
			this.doorPanels.push({
				name: panelRootName, 
				targetCenter: mesh.geometry.boundingSphere.center.clone()
			});
		} else {									// Mesh is the door geometry which needs to animate
			this.doorPanels.push({
				name: panelRootName, 
				mesh: mesh,
				initialCenter: mesh.geometry.boundingSphere.center.clone(),
				initialPosition: mesh.position.clone()
			});
		}
	} else {										// Panel with such name exists. Add missing infos to the existing object. 
		if ( mesh.name.endsWith("O") ){				// Mesh is helper (door in open state)
			this.doorPanels[existingPanelIndex].targetCenter = mesh.geometry.boundingSphere.center.clone();
			
			this.doorPanels[existingPanelIndex].targetPosition = new THREE.Vector3(
					this.doorPanels[existingPanelIndex].initialPosition.x
					+ this.doorPanels[existingPanelIndex].targetCenter.x
					- this.doorPanels[existingPanelIndex].initialCenter.x,
					this.doorPanels[existingPanelIndex].initialPosition.y
					+ this.doorPanels[existingPanelIndex].targetCenter.y
					- this.doorPanels[existingPanelIndex].initialCenter.y,
					this.doorPanels[existingPanelIndex].initialPosition.z
					+ this.doorPanels[existingPanelIndex].targetCenter.z
					- this.doorPanels[existingPanelIndex].initialCenter.z
			);
		} else {									// Mesh is the door geometry which needs to animate
			this.doorPanels[existingPanelIndex].mesh = mesh;
			this.doorPanels[existingPanelIndex].initialCenter = mesh.geometry.boundingSphere.center.clone();
			this.doorPanels[existingPanelIndex].initialPosition = mesh.position.clone();

			this.doorPanels[existingPanelIndex].targetPosition = new THREE.Vector3(
					this.doorPanels[existingPanelIndex].initialPosition.x
					+ this.doorPanels[existingPanelIndex].targetCenter.x
					- this.doorPanels[existingPanelIndex].initialCenter.x,
					this.doorPanels[existingPanelIndex].initialPosition.y
					+ this.doorPanels[existingPanelIndex].targetCenter.y
					- this.doorPanels[existingPanelIndex].initialCenter.y,
					this.doorPanels[existingPanelIndex].initialPosition.z
					+ this.doorPanels[existingPanelIndex].targetCenter.z
					- this.doorPanels[existingPanelIndex].initialCenter.z
			);
		}
	}
};
Elevator.prototype.closeDoors = function() {
	var door;
	this.doorsOpen = false;
	if (this.doorPanels.length < 1) console.log("No door panels configured to close. Mesh names are not correct.");
	for (var i = 0; i < this.doorPanels.length; i++) {
		door = this.doorPanels[i]; 
		helpers.tweenMeshTo(door.mesh, 
			door.initialPosition);
	}
};
Elevator.prototype.openDoors = function() {
	var door;
	this.doorsOpen = true;
	if (this.doorPanels.length < 1) console.log("No door panels configured to open. Mesh names are not correct.");
	for (var i = 0; i < this.doorPanels.length; i++) {
		door = this.doorPanels[i];
		helpers.tweenMeshTo(door.mesh, 
			door.targetPosition);
	}
};