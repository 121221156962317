'use strict';
const THREE = require('three');
import * as Helpers from '../helpers.js';
import * as Config from '../config.js';

export function Building(buildingID) {
	THREE.Group.call( this ); // make Building a subclass of Group. https://github.com/mrdoob/three.js/blob/dev/src/objects/Group.js
	this.type = 'Building';
	this.name = buildingID;
	this.isBuilding = true; 
	this.cores = [];

	var	selected = false, 
		cameraZoom, 
		cameraZoomTarget, 
		material = Config.materials.building,
		icon, iconSelected, iconPosition,
		envelopeFront, envelopeBack, envelopeLines, floors;
}
Building.prototype = Object.assign( Object.create( THREE.Group.prototype ), { // not sure why this is necessary, but it was like that here: https://github.com/mrdoob/three.js/blob/dev/src/objects/Group.js
	constructor: Building,
	isBuilding: true
} );
Building.prototype.dispose = function() {
	// Dispose (remove) the geometries and/or materials from memory where applicable. 
	// Remove mesh/lines/sprite from cores' children array. 
	// Remove mesh/lines/sprite from the parameters of object (ie there will be no more building.floors or building.icon). 
	if (this.envelopeFront) {
		Helpers.dispose(this.envelopeFront, false);
		this.remove(this.envelopeFront);
		delete this.envelopeFront;
	}
	if (this.envelopeBack) {
		Helpers.dispose(this.envelopeBack, false);
		this.remove(this.envelopeBack);
		delete this.envelopeBack;
	}
	if (this.floors) {
		Helpers.dispose(this.floors, false);
		this.remove("floors");
		this.remove(this.floors);
		delete this.floors;
	}
	if (this.envelopeLines) {
		Helpers.dispose(this.envelopeLines, false);
		this.remove(this.envelopeLines);
		delete this.envelopeLines;	
	}
	if (this.icon) {
		Helpers.dispose(this.icon, true);
		this.remove(this.icon);
		delete this.icon;
	}
	if (this.iconSelected) {
		Helpers.dispose(this.iconSelected, true);
		this.remove(this.iconSelected);
		delete this.iconSelected;
	}
	if (this.cores.length > 0) {
		for (var i = 0; i < this.cores.length; i++) {
			if (this.cores[i].mesh) {
				Helpers.dispose(this.cores[i].mesh, false);
				this.cores[i].remove(this.cores[i].mesh);
			}
			if (this.cores[i].icon) {
				Helpers.dispose(this.cores[i].icon, true);
				this.cores[i].remove(this.cores[i].icon);
			}
			if (this.cores[i].iconSelected) {
				Helpers.dispose(this.cores[i].iconSelected, true);
				this.cores[i].remove(this.cores[i].iconSelected);
			}
			// Remove each core from the list of building's children
			this.remove(this.cores[i]);
			// Remove each single core from the array of building.cores[]. Array remains with previous length but with empty elements. 
			delete this.cores[i];	
		}
		// Remove building.cores from the object. 
		delete this.cores;
	}
};
Building.prototype.addFloors = function(importedMesh) {
	if (this.floors) {
		console.log("Error: "+this.name+" has more than one building floors mesh. All exept the first one are turned invisible.");
		Helpers.dispose(importedMesh, true);
		importedMesh.visible = false;
	}
	else {
		this.floors = Helpers.getEdges(importedMesh, Config.materials.buildingSelectedFloor);
		this.floors.visible = false;
		this.floors.name = this.name+"_Decke";
		this.floors.material = Config.materials.buildingSelectedFloor;
		this.attach(this.floors);
	}
};
Building.prototype.addEnvelopeFront = function(importedMesh) {
	if (this.envelopeFront) {
		console.log("Error: "+this.name+" is more than one building envelope front mesh. All exept the first one are turned invisible.");
		Helpers.dispose(importedMesh, true);
		importedMesh.visible = false;
	}
	else {
		this.envelopeFront = importedMesh;
		this.envelopeFront.visible = true;
		this.envelopeFront.name = this.name+"_HuelleVorne";
		this.envelopeFront.material = Config.materials.building;
		this.attach(this.envelopeFront);

		importedMesh.geometry.computeBoundingBox();
		this.iconPosition = new THREE.Vector3(importedMesh.geometry.boundingSphere.center.x, importedMesh.geometry.boundingBox.max.y + Config.iconHover.building, importedMesh.geometry.boundingSphere.center.z);
	}
};
Building.prototype.addEnvelopeLines = function(importedMesh) {
	if (this.envelopeLines) {
		console.log("Error: "+this.name+" is more than one building envelope lines mesh. All exept the first one are turned invisible.");
		Helpers.dispose(importedMesh, true);
		importedMesh.visible = false;
	}
	else {
		this.envelopeLines = Helpers.getEdges(importedMesh, Config.materials.buildingSelectedFloor);
		this.envelopeLines.visible = false;
		this.envelopeLines.name = this.name+"_HuelleLinien";
		this.envelopeLines.material = Config.materials.buildingSelectedFloor;
		this.attach(this.envelopeLines);
	}
};
Building.prototype.addEnvelopeBack = function(importedMesh) {
	if (this.envelopeBack) {
		console.log("Error: "+this.name+" is more than one building envelope back mesh. All exept the first one are turned invisible.");
		Helpers.dispose(importedMesh, true);
		importedMesh.visible = false;
	} 
	else {
		this.envelopeBack = importedMesh;
		this.envelopeBack.visible = true;
		this.envelopeBack.receiveShadow = false; // Possible solution for getting rid of striped shadows of Step2 / selected building. Result is very white tho. 
		this.envelopeBack.name = this.name+"_HuelleHinten";
		this.envelopeBack.material = Config.materials.building;
		this.attach(this.envelopeBack);
	}
};
Building.prototype.hoverStart = function() {
	if (!this.selected){
		if (this.icon) this.icon.visible = false;
		if (this.iconSelected) this.iconSelected.visible = true;
		if (this.envelopeFront) this.envelopeFront.material = Config.materials.buildingHovered;
		if (this.envelopeBack) this.envelopeBack.material = Config.materials.buildingHovered;
	}
};
Building.prototype.hoverEnd = function() {
	if (!this.selected){
		if (this.icon) this.icon.visible = true;
		if (this.iconSelected) this.iconSelected.visible = false;
		if (this.envelopeFront) this.envelopeFront.material = Config.materials.building;
		if (this.envelopeBack) this.envelopeBack.material = Config.materials.building;
		//setSelection("asgeb003", "",false); // for testing
	}
};
Building.prototype.deselectPrevious = function() {
	this.selected = false;
	if (this.icon) this.icon.visible = true;
	if (this.envelopeFront) {
		this.envelopeFront.castShadow = true;
		this.envelopeFront.material = Config.materials.building;
	}
	if (this.envelopeBack)  this.envelopeBack.material = Config.materials.building;
	if (this.floors) this.floors.visible = false;
	if (this.envelopeLines) this.envelopeLines.visible = false;
	for (var i = 0; i < this.cores.length; i++) {
		this.cores[i].deselectPrevious();
		this.cores[i].visible = false;
	}
};
Building.prototype.select = function() {
	this.selected = true;
	if (this.icon) this.icon.visible = false;
	this.iconSelected.visible = false;
	if (this.envelopeFront) {
		this.envelopeFront.castShadow = false;
		this.envelopeFront.material = Config.materials.buildingSelectedFront;
	}
	if (this.envelopeBack) this.envelopeBack.material = Config.materials.buildingSelectedBack;
	if (this.floors) this.floors.visible = true;
	//if (this.envelopeLines) this.envelopeLines.visible = true;
	for (var i = 0; i < this.cores.length; i++) {
		this.cores[i].visible = true;
	}
	//getSelectionIDs();
};
Building.prototype.cameraJumpTo = function() {
	Helpers.cameraJumpTo(
		Config.cameraPosition[this.name] || Config.cameraPosition["initial"],
		Config.targetPosition[this.name] || Config.targetPosition["initial"]);
};
Building.prototype.cameraTweenTo = function() {
	Helpers.cameraTweenTo(
		Config.cameraPosition[this.name] || Config.cameraPosition["initial"],
		Config.targetPosition[this.name] || Config.targetPosition["initial"]);
};