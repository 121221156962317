//***********************************************************************************************************************
// Imports
//***********************************************************************************************************************

import * as Helpers from './helpers.js';
import * as config from './config.js';
import { getElevatorID, ifValidAsgeb, ifValidAsazg, ifValidLiftId, asgebs } from '../city.ts';
import { SceneLight } from './objects/SceneLight.js';
import { Building } from './objects/Building.js';
import { Core } from './objects/Core.js';
import { Elevator } from './objects/Elevator.js';
import { OrbitControls } from './dependencies/OrbitControls.js';
import { GLTFLoader } from './dependencies/GLTFLoader.js';

const THREE = require('three');
import TWEEN from '@tweenjs/tween.js';

const activeIconsPath = './public/assets/icons/active/';
const iconsPath = './public/assets/icons/';

//***********************************************************************************************************************
// Globally accessible variables ( via import { ... } from './index.js' )
//***********************************************************************************************************************

export var scene, camera, controls, elevatorControls;

//***********************************************************************************************************************
// Globally accessible functions
//***********************************************************************************************************************

export {resizeContainer, init, animate, getSelectionIDs, switchToModel, toggleAutoRotate, onDocumentMouseMove, onDocumentMouseDown, onDocumentMouseUp, stepBack}; 

//***********************************************************************************************************************
// Settings
//***********************************************************************************************************************

//Scene and lights
var	container, 
	//stats = new Stats(),
	gui,
	renderer;

//Interaction
var	raycaster, 
	city3DURL= config.modelsPath + 'city.gltf',
	stepID = 1,
	previous,
	cityLoaded = false,
	testMode = false,
	selectedBuilding, 
	selectedCore,
	selectedElevator,
	elevatorControls = {wallOpacity: 0.5, autoRotate: true}, 
	elevatorRotation = 0,
	//showStats = false,
	showGUI = false,
	mouse = new THREE.Vector2(), 
	mouseDownX, 
	mouseDownY,
	domContainer
	;

//***********************************************************************************************************************
// Main Functions
//***********************************************************************************************************************

//init();
//animate();
function resizeContainer(container) {
	domContainer = container;
}

function init(container) {
	camera = new THREE.PerspectiveCamera( 45, container.clientWidth / window.innerHeight, 0.25, 5000 );
	renderer = new THREE.WebGLRenderer( { antialias: true } ); //, logarithmicDepthBuffer: true <- after version 109 this gave weird errors
	controls = new THREE.OrbitControls( camera, renderer.domElement );
	raycaster = new THREE.Raycaster();

	//container = document.createElement( 'div' );
	//document.body.appendChild( container );

	// Setup 3D Scene
	setupScene();
	setupLights();
	setupRenderer(container);
	container.appendChild( renderer.domElement );
	setupCamera();
	setupGui();
	domContainer = container;

	//testMode = true;
	if (testMode){
		//city3DURL = config.modelsPath+'testing/city_testing for multiple meshes.gltf'; //Full export (2x context + buildings) but asgeb006 is doubled. The doubles of everything but the cores are removed. Currently cannot detect if cores are doubled or not. 
		//city3DURL = config.modelsPath+'testing/city_testing with nonexisting as-ids without icon.gltf'; // Partial context export with only asgeb007 and 010. Additionally geometries with asgeb020 and 021 names which wont be shown. 
		//city3DURL = config.modelsPath+'testing/city_testing wrong names small.gltf'; // Full context export with only asgeb007 and 010. Additionally geometries with asgeb020 and 021 names which wont be shown. 
		//city3DURL = config.modelsPath+'testing/city_testing wrong names xl.gltf'; //Full export (context + buildings). . Additionally geometries with asgeb020 and 021 names which wont be shown. 
		city3DURL = config.modelsPath+'testing/city_without_huellevorne.gltf'; // Full context export with only asgeb001, 004, 011, 015 buildings. Additionally asgeb007 which misses envelopeFront. 
		loadCity();
	} else {
		var modelURLs = ["ground", "streets", "trees", "rail", "surrounding"];
		for(var i = 0; i < modelURLs.length; i++){
			city3DURL = config.modelsPath+'city/'+modelURLs[i]+'.gltf';
			loadCity();
		}
		for(var i = 0; i <= asgebs.length-1; i++){
			city3DURL = config.modelsPath+'city/'+asgebs[i]+'.gltf';
			loadCity();
		}
	}
	console.log(scene);
	cityLoaded = true;

	// Event Listeners
	//document.addEventListener( 'mousemove', onDocumentMouseMove);
	//document.addEventListener( 'mousedown', onDocumentMouseDown, false);
	//document.addEventListener( 'mouseup', onDocumentMouseUp, false);
	//document.addEventListener( 'touchstart', onDocumentMouseDown, false);
	//document.addEventListener( 'touchend', onDocumentMouseUp, false);
	window.addEventListener( 'resize', onWindowResize, false );			    
	document.addEventListener( 'keydown', onDocumentKeyDown);	    
}
function animate() {
	limitCamera();
	controls.update();
	render();
	requestAnimationFrame( animate );
	//stats.update();
	TWEEN.update();
}
function render() {
	if (! (stepID == 3) ) {
		checkIntersections();
	}
	renderer.render( scene, camera );
}

//***********************************************************************************************************************
// Setup Functions
//***********************************************************************************************************************

function setupScene() {
	scene = new THREE.Scene();
	scene.background = new THREE.Color( config.skyColor );
	scene.fog = new THREE.Fog( config.skyColor, 2000,5000);	
	scene.elevators = [];
}
function setupLights(){ 
	SceneLight(1200,700,-200,		700,0,-500,		4*350, 1.2*1200, 500/2,  8, -0.00001, false);
	var ambientLight = new THREE.AmbientLight(config.ambientLightColor, config.ambientLightIntensity);
	ambientLight.name = "Scene ambient light";
	scene.add(ambientLight);
}
function setupRenderer(container) {
	renderer.setPixelRatio( window.devicePixelRatio );
	renderer.setSize( container.clientWidth, window.innerHeight );
	renderer.gammaOutput = true;
	renderer.shadowMap.enabled = true;
	renderer.shadowMap.type = THREE.PCFSoftShadowMap;
	//container.appendChild( renderer.domElement );
}
function setupCamera(){
	if (stepID == 3) {
		camera.position.set(config.cameraPosition["elevator"].x,config.cameraPosition["elevator"].y,config.cameraPosition["elevator"].z);
		controls.target.set(config.targetPosition["elevator"].x,config.targetPosition["elevator"].y,config.targetPosition["elevator"].z);

		controls.enablePan = false;
		//controls.enableRotate = false;
		controls.enableZoom = false;

	    controls.enableDamping = false;   //damping
		controls.minPolarAngle = Math.PI/3; // radians
		controls.maxPolarAngle = Math.PI/3; // radians
		controls.minAzimuthAngle = - Infinity; // radians
		controls.maxAzimuthAngle = Infinity; // radians

		controls.update();
	} else {
		if (stepID == 2) {
			if (selectedBuilding) selectedBuilding.cameraJumpTo();
			// else: camera is set inside loadCity>prepareModels
		} else {
			camera.position.set(config.cameraPosition["initial"].x,config.cameraPosition["initial"].y,config.cameraPosition["initial"].z);
		    controls.target.set(config.targetPosition["initial"].x,config.targetPosition["initial"].y,config.targetPosition["initial"].z);
		}
		controls.enablePan = true;
		//controls.enableRotate = false;
		controls.enableZoom = true;

	    controls.enableDamping = true;   //damping 
	    controls.dampingFactor = 0.25;   //damping inertia
		controls.minPolarAngle = Math.PI/6; // radians
		controls.maxPolarAngle = Math.PI/2.1; // radians
		controls.minAzimuthAngle = - Math.PI/2; // radians
		controls.maxAzimuthAngle = Math.PI/3; // radians
		controls.maxDistance = 2000; // radians
		//controls.enableZoom = false;   //damping 
		controls.update();
	}
}
function setupGui(){
	if ((stepID == 3) && showGUI){
		if (gui) {
			gui.domElement.hidden = false;
			return;
		}
		gui = new dat.GUI();
		gui.width = 300;
		gui.domElement.style.userSelect = 'none';
		var folder = gui.addFolder( 'Lifts' );
		folder.add( elevatorControls, 'wallOpacity', 0, 1, 0.01 )
			.name( 'Wall Opacity' )
			.onChange( function () {
				config.materials.eleWallMat.opacity = elevatorControls.wallOpacity;
			} );
		folder.add( elevatorControls, 'autoRotate')
			.name( 'Rotate' )
			.listen();
		folder.open();
	} else {
		if (gui) gui.domElement.hidden = true;
	}
}
function cleanupScene() {
	//console.log("starting cleanupScene")
	for(var i = 0; i < scene.children.length; i++){
		if (scene.children[i].children.length == 0) {
			if (scene.children[i].type == "Scene"){
				scene.children[i].dispose();
				scene.remove(scene.children[i]);
			} else if (scene.children[i].type == "Group") {
				scene.remove(scene.children[i]);
			}
		}
	}
}


//***********************************************************************************************************************
// AS City Specific Functions
//***********************************************************************************************************************
function stepBack() {
	if (stepID == 3 && selectedElevator.sourceBuilding && selectedElevator.sourceBuilding != "") {
		switchToModel(selectedElevator.sourceBuilding);
		elevatorVisibility(false);
	} else {
		switchToModel("");
	}
}


function loadCity(selection) {
	if (cityLoaded){
		cityVisibility(true);
		if(selection){
			setSelectedBuilding(selection, false);
		}
	} else {
	var loader = new THREE.GLTFLoader();
	loader.load(
		city3DURL, 
		function ( gltf ) {
			gltf.scene.traverse( function ( child ) {
			if ( child.isMesh ) {
					child.castShadow = true;
					child.receiveShadow = true;
				}
			} );
			scene.add( gltf.scene );
			//gltf.scene.name = (resourceURL.split('\\').pop().split('/').pop().split('.'))[0]; //JUST NAME 
			gltf.scene.name = city3DURL.split(/[\\\/]/).pop(); //NAME WITH EXTENTION
			render();
			prepareModels(selection);
		},
		function ( xhr ) {
			console.log( ( xhr.loaded / xhr.total * 100 ) + '% loaded' );
		},
		function ( error ) {
			console.log(error);
			console.log( 'An error happened' );
		}

	);
	//cityLoaded = true;
	}
}
function prepareModels(selection){
	// Create Building objects
	var allBuildings = {},
		context,
		building, core;   

	if (scene.getObjectByName("Context")){
		context = scene.getObjectByName("Context");
	} else {
		context = new THREE.Group();
		context.name = "Context";
		scene.add(context);
	}
	//console.log(Object.keys(allBuildings).length);	// to get the number of all the 
	for(var i = 0; i < scene.children.length; i++){
		if (scene.children[i].isScene && scene.children[i].name.includes("gltf")) { 
			var importedScene = scene.children[i];
			for(var j = importedScene.children.length-1; j >= 0; j--){
				var importedMesh = importedScene.children[j];
				
				//All meshes to do with buildings need to be either asgeb00X or asgeb00X_Decke or asgeb00X_asazg00X
				if ( importedMesh.name.includes("asgeb") && ifValidAsgeb(importedMesh.name) ){ 
					building = null;
					
					// Reach a building with that name if it already exists, otherwise create a new and add to allBuidlings. 
					if(allBuildings.hasOwnProperty(importedMesh.name.split("_")[0])){
						building = allBuildings[importedMesh.name.split("_")[0]];
					} else {
						var buildingID = importedMesh.name.split("_")[0]; 
						building = new Building(buildingID); 
						allBuildings[buildingID] = building;
					}

					if ( importedMesh.name.includes("asazg") && ifValidAsazg(importedMesh.name) ){
						core = new Core(importedMesh);
						building.attach(core);
						building.cores.push(core);
					} else {
						importedMesh.renderOrder = 5;
						if ( (importedMesh.name.includes("Floor")) || (importedMesh.name.includes("Decke")) ){
							importedMesh.visible = false;
							building.addFloors(importedMesh);
							if(building.floors){
								importedScene.remove(importedMesh);
								importedMesh.geometry.dispose();
								importedMesh.material.dispose();
								importedMesh = undefined;
							}
						}else if ( (importedMesh.name.includes("EnvelopeBack")) || (importedMesh.name.includes("HuelleHinten")) ){
							building.addEnvelopeBack(importedMesh);
						} else {
							building.addEnvelopeFront(importedMesh);
							building.addEnvelopeLines(importedMesh);
						}
					}
				// Prepare context meshes. 
				} else if ( importedMesh.name.includes("Water") 
					|| importedMesh.name.includes("Street")
					|| importedMesh.name.includes("Bridge")
					|| importedMesh.name.includes("Rail")
					|| importedMesh.name.includes("Tree")
					|| importedMesh.name.includes("Ground")
					|| importedMesh.name.includes("Surrounding")
					) {
					prepareContext(context, importedMesh);
					if (importedMesh.name == "Water" || importedMesh.name == "Streets"){
						prepareContextEdges(context, importedMesh);
					}
					if (importedMesh.name.includes("Tree")) {
						importedMesh.receiveShadow = false;
					}
				// All other meshes are turned invisible.
				} else {
					console.log("Error: Mesh named "+importedMesh.name+" is unknown. It is disposed.");
					Helpers.dispose(importedMesh);
					importedMesh.visible = false;					
				} 
			}
		}
	}
	// Go through all created buildigns and add icons to them. Add buildings to scene. 
	for (var bldg in allBuildings) {
		var thisBuilding = allBuildings[bldg];
		if (!buildingIsComplete(thisBuilding)){
			console.log("Building "+thisBuilding.name+" has missing meshes. It is disposed and removed.");
			thisBuilding.dispose();
			delete allBuildings[thisBuilding.name];
			continue;
		}

		getIcons(thisBuilding);

		for (var i = 0; i < thisBuilding.cores.length; i++) {
			getIcons(thisBuilding.cores[i]);
		}

		scene.add(thisBuilding);

		if (selection && selection==thisBuilding.name){
			thisBuilding.select();
			selectedBuilding=thisBuilding;
			thisBuilding.cameraJumpTo();
		}
	}

	cleanupScene();
}
function buildingIsComplete(building){
	if (!building.envelopeFront) return false;
	if (!building.floors) return false;
	if (building.cores.length < 1) return false;
	return true;
}
function buildingExists(buildingID){
	var buildingName;
	if (buildingID.isBuilding) buildingName = buildingID.name;
	else if (typeof buildingID === 'string') buildingName = buildingID;
	for (var i = 0; i < scene.children.length; i++) { 
		if (scene.children[i].name == buildingName) return true;
	}
	return false;
} 
function getIcons(object){
	var icon, iconSelected, objectID, iconSize;

	if (object.name.includes("asazg")) {
		objectID = object.coreType;
		iconSize = config.iconSizeInitial.core;
		//object.iconPosition.y = (object.parent.iconPosition) ? (object.parent.iconPosition.y-config.iconHover.building+config.iconHover.core) : 20;
	 } else {
		objectID = object.name;
		iconSize = config.iconSizeInitial.building;
	}
	if (object.iconPosition) {
		icon = getIconPNG(objectID, iconsPath+objectID+'.png');
		icon.position.set(object.iconPosition.x, object.iconPosition.y, object.iconPosition.z);
		icon.scale.set(iconSize,iconSize,1); 
		icon.visible=true;
		object.icon = icon;					
		object.attach(object.icon);

		iconSelected = getIconPNG(objectID, activeIconsPath+objectID+'.png');
		iconSelected.position.set(object.iconPosition.x, object.iconPosition.y, object.iconPosition.z);
		iconSelected.scale.set(iconSize,iconSize,1); 
		iconSelected.visible=false;
		object.iconSelected = iconSelected;
		object.attach(object.iconSelected);
	}
}
function getIconPNG(buildingName, path){
	var spriteMap = new THREE.TextureLoader().load( path );
    var spriteMaterial = new THREE.SpriteMaterial( { map: spriteMap, alphaTest: 0.5} ); // color: 0xacacac,  //, color: 0xff0000 // alphaTest https://stackoverflow.com/questions/11165345/three-js-webgl-transparent-planes-hiding-other-planes-behind-them
	//var spriteMaterial = new THREE.SpriteMaterial( { map: spriteMap, alphaTest: 0.5, transparent: true, opacity: 0.95} );

	var icon = new THREE.Sprite( spriteMaterial );
	
	icon.spriteMaterial = spriteMaterial.clone();
	icon.name = buildingName+"_Icon";

	return icon;
}
function prepareContext(context, importedMesh){
	importedMesh.material = getContextMaterial(importedMesh.name); 
	context[importedMesh.name] = importedMesh;
	context.attach(importedMesh);
}
function prepareContextEdges(context, importedMesh){
	var edges= Helpers.getEdges(importedMesh, getContextMaterial(importedMesh.name+"_Edge"));
	context[edges.name] = edges;
	context.attach(edges);
}
function getContextMaterial(meshName){
	if (meshName.includes("Surrounding"))		return config.materials.surroundings;
	else if (meshName.includes("Ground_Green"))	return config.materials.grass;
	else if (meshName.includes("Ground_Urban"))	return config.materials.ground;
	else if (meshName.includes("Ground_Coast"))	return config.materials.coast;
	else if (meshName.includes("Water"))		return config.materials.water;
	else if (meshName.includes("Trees2"))		return config.materials.tree2;
	else if (meshName.includes("Trees3"))		return config.materials.tree3;
	else if (meshName.includes("Trees")) 		return config.materials.tree;
	else if (meshName.includes("Rails"))		return config.materials.rail;
	else if ( meshName.includes("Streets")
			|| meshName.includes("Bridge") ) 	return config.materials.street;
	else if (meshName.includes("Water_Edge")) 	return config.materials.waterEdge;
	else if (meshName.includes("Streets_Edge")) return config.materials.streetEdge;
	else 										return config.materials.ground;
}
function loadElevator(input) {
	TWEEN.removeAll();
	stepID=3;
	var elevatorModelAlreadyLoaded = false;
	var elevatorName = getElevatorID(input);
	if (scene.elevators.length > 0){
		for (var i = 0; i < scene.elevators.length; i++) {
			if (scene.elevators[i].name == elevatorName){
				scene.elevators[i].loadedGeometry.visible = true;
				elevatorModelAlreadyLoaded = true;
				selectedElevator = scene.elevators[i];
				scene.elevators[i].setSource(input);
			} else {
				scene.elevators[i].loadedGeometry.visible = false;
				scene.elevators[i].removeSource();
			}
		}
	}
	if (!elevatorModelAlreadyLoaded){
		var elevator = new Elevator(elevatorName);
		elevator.loadModel();
		elevator.setSource(input);
		selectedElevator = elevator;			
	}
}
function elevatorVisibility(on){
	if(on){
		for (var i = 0; i < scene.elevators.length; i++) {
			if (selectedElevator && selectedElevator.name == scene.elevators[i].name){
				scene.elevators[i].loadedGeometry.visible = true;
			}
		}
	} else {
		for (var i = 0; i < scene.elevators.length; i++) {
			scene.elevators[i].loadedGeometry.visible = false;
		}		
	}	
}
function cityVisibility(on){
	if(on){
		for (var i = 0; i < scene.children.length; i++) {
			if (scene.children[i].isBuilding || scene.children[i].name == "Context"){
				scene.children[i].visible = true;
			} 
		}
	} else {
		for (var i = 0; i < scene.children.length; i++) {
			if (scene.children[i].isBuilding || scene.children[i].name == "Context"){
				scene.children[i].visible = false;
			} 
		}		
	}
}
//***********************************************************************************************************************
// Public functions
//***********************************************************************************************************************

function getSelectionIDs() {
	var selection = [];
	if (selectedBuilding) {selection.push(selectedBuilding.name);}
	else if (selectedElevator) {selection.push(selectedElevator.sourceBuilding);}
	else {selection.push("");}
	if (selectedCore) {selection.push(selectedCore.name);}
	else if (selectedElevator) {selection.push(selectedElevator.sourceCore);}
	else {selection.push("");}
	if (selectedElevator) {selection.push(selectedElevator.name);}
	else {selection.push("");}
	return selection;
}
function logSelectionIDs() {
	console.log( getSelectionIDs() );
}
function switchToModel(modelID){
	var newStepID = 
		( ifValidLiftId(modelID) || (modelID.includes("asazg") && ifValidLiftId(getElevatorID(modelID)) ) ) ? 3 : 
		(modelID.includes("asgeb")) ? 2 :
		1;
	if ( (stepID != 3) || (newStepID == 3) ){
		deselectAll();
		cityVisibility(false);
	}
	if (newStepID == 3) {
		loadElevator(modelID);
		stepID = newStepID;
	} else if (newStepID == 2) {
		cityVisibility(true);
		if (stepID == 3) {
			selectedBuilding = null;
			loadCity(modelID);
			elevatorVisibility(false);
		}
		else {
			setSelectedBuilding(modelID, false);
		}
		stepID = newStepID;
	} else {
		cityVisibility(true);
		if (stepID == 3) {
			loadCity();
			elevatorVisibility(false);
		} else if (stepID == 2) {
			deselectAll();
			Helpers.cameraTweenReset();			
		}
		stepID = newStepID;
	}
	setupCamera();
	setupGui();
}
function toggleAutoRotate(){
	if (elevatorControls.autoRotate) {
		elevatorControls.autoRotate = false;	
	}
	else {
		elevatorControls.autoRotate = true;
	}	
}
//***********************************************************************************************************************
// Private selection functions
//***********************************************************************************************************************
function setSelectedBuilding(buildingID, tween) {
	if (buildingID && buildingExists(buildingID) && buildingID !== "") {
		stepID = 2;
		// Return and throw error if no such building in the scene
		if (!scene.getObjectByName(buildingID) && !scene.getObjectByName(buildingID.name)){
			console.log("No building with such ID available in the scene.");
			console.log(buildingID);
			return;
		}
		var building;
		// Set in case input is a Building object
		if (buildingID.isBuilding){
			building = buildingID;
		// Set if input given with a building name
		} else {
			building = scene.getObjectByName( buildingID, false ); // !!!! 191029 GS: not sure if false/true necessary. and what it does. Check it. 
		}
		// Set the input building to be the selected one
		deselectAll();
		selectedBuilding = building;
		setTimeout( selectedBuilding.select() , 5000); 
		// Move camera to the new selection
		if (tween) {
			selectedBuilding.cameraTweenTo();
		}
		else {
			selectedBuilding.cameraJumpTo();
		}
	} else {
		stepID = 1;
		// Deselct any previous selections (either buildings or cores). Reset to initial view. This shouldn't appear anymore. 
		console.log("No valid buildingID input. Input was "+buildingID);
		deselectAll();
		if (tween)
			Helpers.cameraTweenReset();
		else 
			Helpers.cameraJumpReset();
	}
}		
function deselectAll() {
	if(selectedBuilding) {
		selectedBuilding.deselectPrevious(); 
		selectedBuilding = null;
	}
	if(selectedCore) {
		selectedCore.deselectPrevious(); 
		selectedCore = null;
	}
	if (selectedElevator) {
		selectedElevator = null;
	}
}
//***********************************************************************************************************************
// Camera Functions
//***********************************************************************************************************************

function limitCamera() {
	if(stepID == 3){
		// GS !!! Make it not hardcoded! 
		//console.log(scene.children[3]);
		if(selectedElevator && elevatorControls.autoRotate){
			elevatorRotation += 0.006
			if (selectedElevator.loadedGeometry) selectedElevator.loadedGeometry.rotation.y = elevatorRotation;
		}
	} else {
		// Limiting the camera
		if (camera.position.x < config.cameraLimitations.min.x)
			camera.position.x = config.cameraLimitations.min.x;
		else if (camera.position.x > config.cameraLimitations.max.x)
			camera.position.x = config.cameraLimitations.max.x;

		if (camera.position.z < config.cameraLimitations.min.z)
			camera.position.z = config.cameraLimitations.min.z;
		else if (camera.position.z > config.cameraLimitations.max.z)
			camera.position.z = config.cameraLimitations.max.z;

		// Limiting the camera target
		if (controls.target.x < config.cameraLimitations.min.x-config.cameraTargetLimitationOffset)
			controls.target.x = config.cameraLimitations.min.x-config.cameraTargetLimitationOffset;
		else if (controls.target.x > config.cameraLimitations.max.x+config.cameraTargetLimitationOffset)
			controls.target.x = config.cameraLimitations.max.x+config.cameraTargetLimitationOffset;

		if (controls.target.z < config.cameraLimitations.min.z-config.cameraTargetLimitationOffset)
			controls.target.z = config.cameraLimitations.min.z-config.cameraTargetLimitationOffset;
		else if (controls.target.z > config.cameraLimitations.max.z+config.cameraTargetLimitationOffset)
			controls.target.z = config.cameraLimitations.max.z+config.cameraTargetLimitationOffset;
	}
}

//***********************************************************************************************************************
// Interaction and Events
//***********************************************************************************************************************
function onDocumentKeyDown(event) {
	var key = event.key;
	if (key == "l"){
		if (selectedBuilding){
			selectedBuilding.envelopeLines.visible = !selectedBuilding.envelopeLines.visible;
		}
	} else if (key == "1"){
		switchToModel("");
	} else if (key == "2"){
		switchToModel("asgeb001");
	} else if (key == "3"){
		switchToModel("asgeb003");
	} else if (key == "4"){
		switchToModel("S1223G0"); //("S1123G0"); //switchToModel("31B422");
	} else if (key == "5"){
		switchToModel("asgeb001_asazg001_1");
	} else if (key == "6"){
		switchToModel("asazg002");
	} else if (key == "7"){
		var elevators = ["31B122", "31B222", "31B322", "31B422", "S1123G0", "S1223G0"];
		switchToModel(elevators[Math.floor(elevators.length * Math.random())]);
	} else if (key == "0"){	
		toggleAutoRotate();
	} else if (key == "c"){
		//switchToModel("");
		if (selectedBuilding) console.log(selectedBuilding.name);
		logCameraPosition();
	} else if (key == "p"){
		console.log(scene);
	} else if (key == "a"){
		switchToModel("asgeb007");
	} else if (key == "r"){
		Helpers.cameraTweenReset();
	} else if (key == "q"){
		// To always go one step back (ie from elevator to building or from building to city)
		if (stepID == 3 && selectedElevator.sourceBuilding && selectedElevator.sourceBuilding != "") {
			switchToModel(selectedElevator.sourceBuilding);
		} else {
			switchToModel("");
		}
	} else if (key == "g"){
		logSelectionIDs();
	} else if (key == "m"){
		cityVisibility(true);
	} else if (key == "n"){
		cityVisibility(false);
	} else if (key == "M"){
		elevatorVisibility(true);
	} else if (key == "N"){
		elevatorVisibility(false);
	} else if (key == "b"){
		console.log(scene);
	} else if (key == "v"){
		cleanupScene();
	} else if (key == "d"){
		if (stepID == 3 && selectedElevator){
			// NB for this the door meshes need to be named with following convention: 
				//NEW: L4_P1_C : Landing door Side 3 (Left) _ Panel 4 _ Closed
				//NEW: C1_P1_O : Cabin door Side 1 (Front) _ Panel 1 _ Open
			if (selectedElevator.doorsOpen) selectedElevator.closeDoors();
			else selectedElevator.openDoors();
		}
	}
}
function logCameraPosition(){
	console.log(
		Math.floor(camera.position.x)+","+
		Math.floor(camera.position.y)+","+
		Math.floor(camera.position.z)+"	"+
		Math.floor(controls.target.x)+","+
		Math.floor(controls.target.y)+","+
		Math.floor(controls.target.z) );	
}
function onWindowResize() {
	camera.aspect = domContainer.clientWidth / window.innerHeight;
	camera.left = - config.frustumSize * camera.aspect / 2;
	camera.right = config.frustumSize * camera.aspect / 2;
	camera.top = config.frustumSize / 2;
	camera.bottom = - config.frustumSize / 2;
	camera.updateProjectionMatrix();
	renderer.setSize( domContainer.clientWidth, window.innerHeight );
}
function onDocumentMouseMove( event ) {
	event.preventDefault();
	mouse.x = ( event.clientX / domContainer.clientWidth ) * 2 - 1;
	mouse.y = - ( event.clientY / window.innerHeight ) * 2 + 1;
}
function onDocumentMouseDown( event ) {
	mouseDownX = event.clientX;
	mouseDownY = event.clientY;
}
function onDocumentMouseUp( event ) { // gotta check both down and up https://github.com/mrdoob/three.js/issues/1410 
	if ( (stepID != 3) && (mouseDownX == event.clientX) && (mouseDownY == event.clientY) ){
    	//var intersected = previous;
    	if (previous){
    		onClickedBuilding(previous);
    	}
	}	
	return stepID;
}
function onClickedBuilding(intersected){
	/*if (selectedCore){
		console.log("This shouldn't happen: while clicking a building, there is also a selected core. When there is a selected core, we should be in Z3 without the city and buildings.");
	}*/
	if (selectedBuilding){
		if (intersected.isCore) {
			stepID = 3;
			//onClickedCore(intersected);
			//console.log("A core is selected. In the future: unload the city and load the respective elevator.")
			selectedCore=intersected;
			switchToModel(intersected.name);
		} else {
			if (intersected == selectedBuilding){
				stepID = 1;
				deselectAll();
				Helpers.cameraTweenReset();					
			} else {
				stepID = 2;
				setSelectedBuilding(intersected, true);
			}
		}
	} else {
		setSelectedBuilding(intersected, true);
	}
} 
/* NOT CALLED
function onClickedCore(intersected){
	if (selectedCore) {
		if(intersected == selectedCore){
			selectedCore.deselectSameType(selectedCore.coreType);
			selectedCore.parent.cameraTweenTo();
			stepID = 2;
			selectedCore = null;		
		} else if (intersected.coreType == selectedCore.coreType){
			selectedCore = intersected;
			selectedCore.cameraTweenTo();		
		} else {
			selectedCore.deselectSameType(selectedCore.coreType);
			selectedCore = intersected;
			selectedCore.selectSameType(selectedCore.coreType);
			selectedCore.cameraTweenTo();			
		}
	} else {
		selectedCore = intersected;
		selectedCore.selectSameType(selectedCore.coreType);
		selectedCore.cameraTweenTo();		
	}
}
*/
function startHover(object) {
	//if (!object.selected) {
		object.hoverStart();
	//}
	previous = object;	
}
function endHover(object) {
	//if (!object.selected) {
		object.hoverEnd(); 
	//}
}
function switchHover(object) {
	endHover(previous);
	startHover(object);
}
function checkIntersections() {
	raycaster.setFromCamera( mouse, camera );
	var intersects = raycaster.intersectObjects( scene.children, true );
	if (intersects.length > 0){
		if (intersects[0].object.parent.isCore){ 
			if (previous){
				if (previous.isCore){
					if (previous.coreType == intersects[0].object.parent.coreType){
						previous = intersects[0].object.parent;
					} else {
						switchHover(intersects[0].object.parent);
					}
				} else if (previous.isBuilding){
					if (selectedBuilding && (previous == selectedBuilding) ){
						startHover(intersects[0].object.parent);
					} else {
						switchHover(intersects[0].object.parent);						
					}
				}
			} else {
				startHover(intersects[0].object.parent);
			}
		} else if (intersects[0].object.parent.isBuilding){
			if (selectedBuilding && (intersects[0].object.parent == selectedBuilding) ){
				checkCoreIntersections(intersects);
			} else {
				if (previous){
					if (previous != intersects[0].object.parent){
						switchHover(intersects[0].object.parent); 
					} 
				} else {
					startHover(intersects[0].object.parent);
				}
			}
		} else {
			endAnyHovers();	
		}
	} else {
		endAnyHovers();
	}
}
function checkCoreIntersections(intersects) {
	var firstCore;

	if (!intersects) {
		return;
	}
	if ( (selectedBuilding) && (
		(selectedBuilding.envelopeFront == intersects[0].object)
		//|| (selectedBuilding.envelopeBack == intersects[0].object) 
		|| (selectedBuilding.envelopeLines == intersects[0].object) 
		|| (selectedBuilding.floors == intersects[0].object)	
		) ){
		// GET FIRST INTERSECTING ELEVATOR, IF IT EXISTS
		firstCore = null; 
		for (var i = 0; i < intersects.length; i++) {
			if (intersects[i].object.parent.isCore) {
				firstCore = intersects[ i ].object.parent;
				break;
			}								
		}

		if (firstCore){
			if (previous){
				if (previous.isBuilding){
					if (previous != selectedBuilding){
						switchHover(firstCore); 
					} else {
						startHover(firstCore);
					}
				} else if (previous.isCore) {
					if (previous.coreType != firstCore.coreType){
						switchHover(firstCore);
					} else if (previous != firstCore){
						// GS: !! tseki seda. Preagu: kirjuta previous üle ükskoik kas hover on liikunud teise samatüübilise lifti peale v ei. 
						previous = intersects[0].object.parent;
					} else {
					}
				} 
			} else {
				startHover(firstCore);
			}
		} else {
			if (previous){
				// GS: !!! tseki seda. Preagu: Kui pole sama mis praegu, l6peta eelmise hover ja Set selectedBuilding as previous
				if (previous != intersects[0].object.parent){
					endHover(previous); //previous.hoverEnd();
					previous = intersects[0].object.parent;
				} 
			} else {
				// GS: !!! tseki seda. Preagu: Set selectedBuilding as previous
				previous = intersects[0].object.parent;
			}
		}
	}
}
function endAnyHovers(){
	if (previous){
		previous.hoverEnd();
		previous = null;
	}
}