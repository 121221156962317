'use strict';
import $ from "jquery";

// @ts-ignore
import { stepData, uploadData, worldElement, sidebarElement } from './index.ts';
import { resizeContainer, init, animate, getSelectionIDs, switchToModel, elevatorControls, 
	onDocumentMouseMove, onDocumentMouseDown, onDocumentMouseUp, stepBack  } from './city/scene';
	import { getAsazg } from './city/helpers.js';

let navBack;
let dataInterface;

/**
 * CityInterface class
 */
class DataInterface {
	public activeStepId			: number;
	public previousStepId		: number;
	public buildingType			: string;
	public mobilityType			: string;
	public data					: {};

	constructor(activeStepId, previousStepId, data) {
		this.activeStepId 		= activeStepId;
		this.previousStepId 	= previousStepId;
		this.data 				= data;
		this.mobilityType		= null;
		this.buildingType		= null;
	}
}

export function registerEventsUI() { 
	navBack = document.getElementById("sidebar-nav-back");
	navBack.addEventListener("click", stepBack, false);
}

function start(world) {
	
	dataInterface = new DataInterface(1, null, {});
    init(world);
	animate();

	// Event Listeners
	document.addEventListener( 'mousemove', mMove);
	document.addEventListener( 'mousedown', mDown, false);
	document.addEventListener( 'mouseup', mUp, false);

	var request = indexedDB.open("library");
}

function displayElement(domElement) {
	domElement.style.display = "block";
}

function hideElement(domElement) {
	domElement.style.display = "none";
}

function mUp(event) {
	let sid = onDocumentMouseUp(event);
	const nbt = document.getElementById("copyright");
	const worldElement = <HTMLElement>document.body.querySelector('.aspn-3d-world');

	// WORLD INTERACTIONS
	if(event.target.localName == "canvas"){
		if(sid == 1) {
			console.log("1");
			if(sidebarElement) sidebarElement.classList.add('invisible');
			displayElement(nbt);
		} else if(sid == 2) {
			console.log("2");
			if(sidebarElement) sidebarElement.classList.add('invisible');
			displayElement(nbt);
		} else if(sid == 3) {
			console.log("3");	
			var selection = getSelected();
			if (dataInterface.buildingType == null || dataInterface.mobilityType == null) {
				dataInterface.buildingType = selection[0];
				dataInterface.mobilityType = selection[1];
				uploadData(111, {
					"buildingType": dataInterface.buildingType,
					"mobilityType": dataInterface.mobilityType
				});
			}

			sidebarElement.classList.remove('invisible');
			worldElement.classList.toggle("translated");
			hideElement(nbt);
		} else {
			console.log(sid);
		}
	} 
	// UI INTERACTIONS
	else {
		if(sid == 1) {
			console.log("1");
			sidebarElement.classList.add('invisible');
			displayElement(nbt);
		} else if(sid == 2) {
			console.log("2");
			sidebarElement.classList.add('invisible');
			displayElement(nbt);
		} else if(sid == 3) {
			let clickedId = event.target.id;

			if(clickedId == undefined || clickedId == "") 
				return;

			let clicked = $('#'+ clickedId);

			if(clicked.attr("data-type") == "value") {
				let button = clicked.parent();
				let selected = button.siblings().find(".underscore");
				selected.toggleClass('underscore');
				selected.toggleClass('enabled');
				
				clicked.toggleClass('underscore');
				clicked.toggleClass('enabled');

				if(clickedId == "schachtzugnge-bereck") {
					stepData.body.selection1.posList[0].valItems[8].valList[0].values[0].selected = false;
					stepData.body.selection1.posList[0].valItems[8].valList[0].values[2].selected = true;
					uploadData(111, stepData.body);
				}
			}

			if(clicked.tagName == "path") { 
				registerEventsUI();
				sidebarElement.classList.toggle('invisible');
				worldElement.classList.remove("translated");
				sid = 2;
			} else if (clicked.className != undefined && clicked.className != "" && clicked.className.toString().includes("icon-l")) {
				
			}
		}
	}
}

function mDown(event) {
	onDocumentMouseDown(event);
}

function mMove(event) {
	onDocumentMouseMove(event);
}

function getSelected() {
	var selected = getSelectionIDs();
	return {
		"buildingType": selected[0],
        "mobilityType": selected[1]
	};
}
function setSelected(modelID) {
	switchToModel(modelID);
}
function setElevatorOpacity(input) {
	elevatorControls.wallOpacity = input;
}
function toggleRotate(input) {
	elevatorControls.autoRotate = input;
}
function getElevatorID(input) {
	return tempService(input);
}
function ifValidAsgeb(input) {
	return tempValidation(input);
}
function ifValidAsazg(input) {
	return tempValidation(input);
}
function ifValidLiftId(input) {
	return liftIds.includes(input); 
	//return tempValidation(input);
}
export { 
    DataInterface, start, getSelected, setSelected, setElevatorOpacity, toggleRotate, getElevatorID, ifValidAsgeb, ifValidAsazg, ifValidLiftId, asgebs
}; 

var asgebs = ["asgeb001", "asgeb002", "asgeb003", "asgeb004", "asgeb005", "asgeb006", "asgeb007", "asgeb008", "asgeb009", "asgeb010", "asgeb011", "asgeb012", "asgeb013", "asgeb014", "asgeb015"];
var asazgs = ["asazg001", "asazg002", "asazg003", "asazg004", "asazg005", "asazg006", "asazg007", "asazg008"];
var liftIds = ["31B122", "31B222", "31B322", "31B422", "S1123G0", "S1223G0"];

function tempService(elevatorSource){
	var coreID = getAsazg(elevatorSource);
	//var buildingID = getAsgeb(elevatorSource);
	if (elevatorSource.includes("asazg")){
		// Return LiftID in case input is a coreID (or possibly also buildingID in the future). 
		if (coreID == "asazg001") return liftIds[1];
		else if (coreID == "asazg002") return liftIds[2];
		else if (coreID == "asazg003") return liftIds[3];
		else if (coreID == "asazg004") return liftIds[4];
		else if (coreID == "asazg004") return liftIds[5];
		else return liftIds[0];
	} else {
		// In case elevatorSource is itself a valid LiftID, return itself. 
		if (ifValidLiftId(elevatorSource)) 
			return elevatorSource;
		else {
			console.log("Error: No LiftID found. Check the elevatorSource input.")
			return "";
		}
	}
}

function tempValidation(source){
	var sourceArray = source.split("_");
	if (source.includes("asgeb")){
		if (source.includes("asazg")){
			// Recognize if source is in format "asgeb00x_asazg00y"
			return asazgs.includes(sourceArray[1]);
		}
		// Recognize if source is in format "asgeb00x"
		return asgebs.includes(sourceArray[0]);
	} else if (source.includes("asazg")){
		// Recognize if source is in format "asazg00y"
		return asazgs.includes(sourceArray[0]);
	} else {
		// Recognize if source is a valid liftID
		return liftIds.includes(source);
	}
}
