'use strict';
const THREE = require('three');
import { scene } from '../scene.js';
import { sceneLightColor, sceneLightIntensity } from '../config.js';

export function SceneLight(px,py,pz,		tx,ty,tz,		shadowCameraDimension,far,near,		shadowMapMultiplyer, bias, ifHelper) {
	var light = new THREE.DirectionalLight(sceneLightColor, sceneLightIntensity);
	var targetObject = new THREE.Object3D();

    //***********************************************************************************************************************
    // Light Target Setup
    //***********************************************************************************************************************
	targetObject.name = "Light target";
    targetObject.position.set(tx,ty,tz);
    scene.add(targetObject);

    //***********************************************************************************************************************
    // Light Setup
    //***********************************************************************************************************************
    light.name = "Scene light";
    light.position.set(px,py,pz);
	light.target = targetObject;
    
    //***********************************************************************************************************************
    // Shadow and Shadow Camera Settings
    //***********************************************************************************************************************
    light.castShadow = true;
    light.shadow.mapSize.width = 512*shadowMapMultiplyer;
    light.shadow.mapSize.height = 512*shadowMapMultiplyer;
    //light.shadow.radius = 1.05; //1.24; // GS: teeb nt parkimismaja seinale laigud// https://threejs.org/docs/#api/en/lights/shadows/LightShadow
    light.shadow.bias = bias; //0.0001; //https://discourse.threejs.org/t/a-problem-with-directionallight-and-shadow/850   how to implement: https://stackoverflow.com/questions/31614317/shadow-aliasing-with-shadermaterial-on-three-js 

    light.shadow.camera.left = -shadowCameraDimension;
    light.shadow.camera.right = shadowCameraDimension;
    light.shadow.camera.top = shadowCameraDimension;
   	light.shadow.camera.bottom = -shadowCameraDimension;
    light.shadow.camera.far = far; // test against zfighting https://stackoverflow.com/questions/40328722/how-can-i-solve-z-fighting-using-three-js
    light.shadow.camera.near = near;

    //***********************************************************************************************************************
    // Helper for Troubleshooting, if Applicable
    //***********************************************************************************************************************
	if (ifHelper) {
		var helper = new THREE.CameraHelper( light.shadow.camera );
		scene.add( helper );
	}

    //***********************************************************************************************************************
    // Add the Light to the Scene
    //***********************************************************************************************************************
    scene.add(light);
}

SceneLight.prototype = Object.assign( Object.create( THREE.Group.prototype ), { // not sure why this is necessary, but it was like that here: https://github.com/mrdoob/three.js/blob/dev/src/objects/Group.js
	constructor: SceneLight,
	isLight: true
} );