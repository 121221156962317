'use strict';

import * as Handlebars from '../node_modules/handlebars/dist/handlebars.js';

Handlebars.registerPartial(
    "ListOptionSelector",
    `
    <!-- ListOptionSelector -->
    <div class="bar btn-toolbar sidebar-card d-flex justify-content-around" role="toolbar" aria-label="Toolbar with title and Options">
        <div class="title-group" role="group" aria-label="Title Group">

        </div>
        <div class="btn-group" role="group" aria-label="Option Group">
            {{#each valList.0.values}}
                {{#if selected}}
                    <button type="button" class="d-flex flex-column base-btn">
                        {{#ifEquals ../valList.0.selection "MULTIPLE"}}
                            <img class="icon-l my-1 h-2x" src="public/assets/nav_icons/selected/{{removeSpecial value}}.svg">
                            <p id="{{removeSpecial ../valTitle}}-{{ removeSpecial value}}" data-type="value" class="my-0">{{value}}</p>
                        {{else}}
                            <p id="{{removeSpecial ../valTitle}}-{{ removeSpecial value}}" data-type="value" id="" class="my-2 underscore">{{value}}</p>
                        {{/ifEquals}}
                    </button>
                {{else if enabled}}
                    <button type="button" class="d-flex flex-column base-btn">
                        {{#ifEquals ../valList.0.selection "MULTIPLE"}}
                            <img class="icon-l my-1 h-2x" src="public/assets/nav_icons/enabled/{{removeSpecial value}}.svg">
                            <p id="{{removeSpecial ../valTitle}}-{{ removeSpecial value}}" data-type="value" class="my-0">{{value}}</p>
                        {{else}}
                            <p id="{{removeSpecial ../valTitle}}-{{ removeSpecial value}}" data-type="value" class="my-2 enabled">{{value}}</p>
                        {{/ifEquals}}
                    </button>
                {{else}}
                    <button type="button" class="d-flex flex-column base-btn" disabled>
                        {{#ifEquals ../valList.0.selection "MULTIPLE"}}
                            <img class="icon-l my-1 h-2x" src="public/assets/nav_icons/disabled/{{removeSpecial value}}.svg" style="--color_fill: #000;">
                            <p id="{{removeSpecial ../valTitle}}-{{ removeSpecial value}}" data-type="value" class="my-0">{{value}}</p>
                        {{else}}
                            <p id="{{removeSpecial ../valTitle}}-{{ removeSpecial value}}" data-type="value" class="my-2 disabled">{{value}}</p>
                        {{/ifEquals}}
                    </button>
                {{/if}}
            {{/each}}
        </div>
    </div>
    `
);

Handlebars.registerPartial(
    "OptionSwitchSelector",
    `
    <!-- OptionSwitchSelector -->
    <div class="row m-3">

        <div class="col-4 pr-0">
            <div class="row"><h6 class="my-1">{{valTitle}}</h6></div>
            <div class="row"><small class="my-1 text-secondary">{{subTitle}}</small></div>
        </div>

        <div class="col-8 d-flex mt-2 justify-content-around px-0">
            
            <div class="col-4 px-0">
                {{#if valSingleValue}}
                    <h6 class="my-1 mx-3 selected">{{valLabelTitle}}</h6>
                {{else}}
                    <h6 class="my-1 mx-3 disabled">{{valLabelTitle}}</h6>
                {{/if}}
            </div>

            <div class="col px-0 flex-grow-0">
                {{#if valSingleValue}}
                    <h2 class="my-1 mx-3 selected">{{valLabelValue}}</h2>
                {{else}}
                    <h2 class="my-1 mx-3 disabled">{{valLabelValue}}</h2>
                {{/if}}
            </div>

            <div class="col px-0 flex-grow-0">
                <div class="flex-column mx-3">
                    <img class="icon-l my-1 h-2x" src="public/assets/nav_icons/enabled/{{removeWhite valTitle}}.svg">
                </div>
            </div>

            <div class="col px-0 flex-grow-0">
                <div class="checkbox">
                    <label class="switch mt-2">
                        <input type="{{groupType}}" data-toggle="toggle" class="price" name="{{groupName}}" data-value="{{price}}">
                        <span class="slider round"></span>
                        <span><h6 class="mt-5 mb-0" style="font-size: small; width: max-content;">{{display_price}}</h6></span>
                    </label>
                </div>
            </div>

        </div>

    </div>
    `
);


Handlebars.registerPartial(
    "ScrollSelector",
    `
    <!-- ScrollSelector -->

    `
);

Handlebars.registerPartial(
    "SliderSelector",
    `
    <!-- SliderSelector -->
    <div class="row w-100 mx-0">
        <div class="col-2 d-flex justify-content-start">
            <span>{{valList.0.values.0.value}}</span>
        </div>
        <div class="col"></div>
        <div class="col-2 d-flex justify-content-end">
            <span>{{lastValue valList.0.values}}</span>
        </div>
        <div class="col-2 d-flex justify-content-end">
        </div>
    </div>
       
    <div class="row w-100 mx-3">
        <div id="rangeSlider" class="col range-slider pl-0"></div>
        <div class="col-2 d-flex justify-content-end">
        </div>
    </div>

    <div class="row w-100 mx-0">
        <div class="col-2 d-flex justify-content-start">
            <span>{{valList.1.values.0.value}}</span>
        </div>
        <div class="col"></div>
        <div class="col-2 d-flex justify-content-end">
            <span>{{lastValue valList.1.values}}</span>
        </div>
        <div class="col-2 d-flex justify-content-end">
        </div>
    </div>
    `
);

Handlebars.registerPartial(
    "Planningparameters",
    `
    <!-- Planningparameters -->
    <div class="card fade-in">
        <div class="card-header mt-3" id="{{posText}}">
            <button class="btn text-left" data-toggle="collapse" data-target=".{{name}}" aria-expanded="true">
                <h5>{{posText}}</h5>
            </button>
        </div>

        <!-- Nutzungen -->
        {{#each valItems}}
            <div id="{{valSite}}" class="collapse show {{../name}}" aria-labelledby="{{../posText}}" data-parent="#accordion">
                {{#if valInfo}}
                    <i class="fas fa-info-circle link" data-toggle="popover" data-content={{valInfo}}></i>
                {{else}}
                {{/if}}
                {{#if valVisible}}    
                    <div class="card-body py-0">
                        <!-- SWITCHES -->
                        {{#if valSingleValue}}
                            {{> OptionSwitchSelector}}
                        <!-- LISTS -->    
                        {{else if valList.0.values}}                 
                            <div class="row m-3 justify-content-between">             
                                <!-- ListOptionSelector -->
                                {{#ifEquals valList.0.layout "HORIZONTAL"}}
                                    <h6 class="my-3">{{valTitle}}</h6>
                                    <ol data-type="valTitle" id="{{valTitle}}" class="mx-0 my-0 noIndent">
                                    {{> ListOptionSelector}}
                                <!-- Horizontal Slider -->
                                {{else ifEquals valList.0.layout "GRID"}}
                                    <h6 class="my-3">{{valTitle}}</h6>
                                    {{> SliderSelector}}
                                {{else}}
                                    <h6 class="my-3">{{valTitle}}</h6>
                                    <ol id="{{valTitle}}" class="mx-0 my-0 noIndent">
                                {{/ifEquals}}
                                </ol>
                            </div>
                        {{else}}
 
                        {{/if}}
                    </div>
                {{else}}
                {{/if}}
            </div>
        {{/each}}
    </div>
    `
);

Handlebars.registerPartial(
    "Recommendation",
    `
    <!-- Recommendation -->
    <div class="card fade-in">
        <div class="card-header mt-3" id="{{posText}}">
            <button class="btn text-left" data-toggle="collapse" data-target=".{{name}}" aria-expanded="true">
                <h5 class="text-danger">{{posText}}</h5>
            </button>
        </div>
        {{#each valItems}}
            {{#if valVisible}}    
                <div class="card-body py-0">
                    <!-- SWITCHES -->
                    {{#if valList.0.values}}
                        <div class="card-header mt-3 mx-4 bg-light">
                            <div class="row mt-3 mx-1 px-2 d-flex justify-content-start">
                                <div class="col-">
                                    <h5 class="text-danger">{{valTitle}}</h5>
                                </div>
                                <div class="col">
                                    <p class="text-danger">{{valSubTitle}}</p>
                                </div>
                                <div class="col-">
                                    <button id="inputId" type="submit" class="btn btn-default text-secondary shift-l">
                                        <i class="fas fa-download"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="card-body mx-4 pb-0 shadow">
                            <div class="row">
                            
                                <div class="col-4">TEST</div>
                                <div class="col-8 px-0">
                                    <table class="table">  
                                        {{#each valList}}
                                        <tr>
                                            <td class="pt-2 pb-2 pl-0 justify-content-start">{{units}}</td>
                                            <td class="pt-2 pb-2 pl-0 justify-content-end">{{values.0.value}}</td>
                                        </tr>
                                        {{/each}}
                                    </table>
                                </div>

                            </div>
                        </div>
                    {{else}}
                    {{/if}}
                </div>
            {{else}}
            {{/if}}
        {{/each}}
    </div>
    `
);

Handlebars.registerPartial(
    "MatchingProducts",
    `
    <!-- MatchingProducts -->
    <div class="card fade-in">
        <div class="card-header mb-3 py-0" id="{{posText}}">
            <div class="card-body mx-4 bg-highlight d-flex justify-content-around shadow">
                <button class="btn text-left" data-toggle="collapse" data-target=".{{name}}" aria-expanded="true">
                    <h5 class="text-white">{{posText}}</h5>
                </button>
            </div>
        </div>
    </div>
    `
);

Handlebars.registerPartial(
    "navbarLinks",
    `
    <!-- navbarLinks -->
    <div class="bar btn-toolbar sidebar-footing bg-light d-flex justify-content-around" role="toolbar" aria-label="Toolbar with button groups">
        <a href="" class="pb-3 d-flex flex-column">
            <i class="pt-3 fas fa-download link icon-m"></i>
            <h6 class="text-secondary p-2">Download</h6>
        </a>
        <a href="" class="pb-3 d-flex flex-column">
            <i class="pt-3 fas fa-file-import link icon-m"></i>
            <h6 class="text-secondary p-2">Liftdevis</h6>
            </a>
        <a href="" class="pb-3 d-flex flex-column">
            <i class="pt-3 fas fa-swatchbook link icon-m"></i>
            <h6 class="text-secondary p-2">Liftdesign</h6>
            </a>
        <a href="" class="pb-3 d-flex flex-column">
            <i class="pt-3 fas fa-comments link icon-m"></i>
            <h6 class="text-secondary p-2">Beratung</h6>
            </a>
        <a href="" class="pb-3 d-flex flex-column">
            <i class="pt-3 fas fa-info-circle link icon-m"></i>
            <h6 class="text-secondary p-2">Normen</h6>
        </a>
    </div>
`);

Handlebars.registerHelper("lastValue", function(array) {
    return array[array.length-1].value;
  });

Handlebars.registerHelper('removeWhite', function(str) {
    return str.split(' '). join('');
});

Handlebars.registerHelper('removeSpecial', function(str) {
    return str.replace(/[^a-zA-Z ]/g, "").toLowerCase();
});

Handlebars.registerHelper('ifEquals', function(arg1, arg2, options) {
    return (arg1 == arg2) ? options.fn(this) : options.inverse(this);
});

(function() {
    function checkCondition(v1, operator, v2) {
        switch(operator) {
            case '==':
                return (v1 == v2);
            case '===':
                return (v1 === v2);
            case '!==':
                return (v1 !== v2);
            case '<':
                return (v1 < v2);
            case '<=':
                return (v1 <= v2);
            case '>':
                return (v1 > v2);
            case '>=':
                return (v1 >= v2);
            case '&&':
                return (v1 && v2);
            case '||':
                return (v1 || v2);
            default:
                return false;
        }
    }

    Handlebars.registerHelper('ifCond', function (v1, operator, v2, options) {
        return checkCondition(v1, operator, v2)
                    ? options.fn(this)
                    : options.inverse(this);
    });

}());

export const main = Handlebars.compile(`
    <!-- HEADER -->
    <div class="aspn-navbar fixed-top shadow-3"></div>

    <div class="container-fluid" id="wrapper">
        <div class="row">
            <div class="aspn-3d-world col px-0"></div>
            <div class="aspn-sidebar col-4 px-0 overflow-hidden shadow invisible"></div>
        </row>
    </div>

    <!-- FOOTER -->
    <footer class="aspn-footer"></footer>
`);

export const id = Handlebars.compile(`
    <!-- LIFT ID -->

`);

export const sidebar = Handlebars.compile(`
    <!-- Sidebar -->
    <div class="border-right flex-column" id="sidebar-wrapper">
      <div class="bar sidebar-heading bg-light">
        <a class="link" id="sidebar-nav-back" class="btn aspn-button">
            <i class="fas fa-arrow-left"></i>
        </a>
        <h5 class="float-right">DE</h5>
      </div>
      <div id="accordion" class="bg-white">
        {{#if title}}
        <div class="container">    
            <div class="row m-3">
                <h5>{{title}}</h5><h5 class="mx-2"> > </h5><h5 class="selected">{{selection1.title}}</h5>
            </div>
        </div>
        {{else}}
        {{/if}}
        {{#each selection1.posList}}
            {{#if posVisible}}              
                {{#ifEquals name "LIST1"}}
                    {{> Planningparameters}}
                {{else ifEquals name "LIST2"}}
                    {{> Recommendation}}
                {{else ifEquals name "LIST3"}}
                    {{> MatchingProducts}}
                {{else}}
                {{/ifEquals}}       
            {{else}}
            {{/if}}
            
        {{/each}}
      </div>
      {{> navbarLinks}}
    </div>
`);

export const navbar = Handlebars.compile(`
    <div class="collapse bg-white" id="navbarToggleExternalContent">
        <div class="card rounded-0 position-absolute text-white" style="right:0; height:100%">
            <div class="card-body bg-red p-3">
                <div class="row card-title mb-0">
                    <button id="lId" class="nav-link btn btn-link flex-sm-fill text-white active" href="">
                        <i class="fas fa-id-card"></i>
                        Lift ID
                    </button>
                    <div id="lId-details" class="group" style="display:none";>
                        <form id="idForm" style="align-self:center; width:min-content;">
                            <div class="form-group d-flex" style="margin-bottom:0;">
                                <input type="text" class="form-control rounded" style="width:8rem;" id="liftId" placeholder="S1132H">
                                <button id="inputId" type="submit" class="btn btn-default text-secondary shift-l">
                                    <i class="fas fa-arrow-right"></i>
                                </button>
                               
                            </div>
                        </form>
                        <button id="lIdInfo" class="nav-link btn btn-link flex-sm-fill text-dark active info" href="">
                            <i class="info fas fa-info-circle"></i>
                        </button>
                    </div>  
                </div>
                <div id="lId-info" class="card-text" style="max-width:17rem; display:none">
                    <div class="mt-5">
                        <p>Die Lift ID finden Sie auf der Titelseite Ihrer Offerte – oder fragen Sie Ihren Liftberater in der Nähe.</p>
                    </div>
                    
                </div>    
            </div>
        </div>
        <ul class="nav nav-fill p-3">
            {{#each navbar_items}}
                <li class="nav-item">
                    <a class="nav-link flex-sm-fill text-secondary {{status}}" href="#{{slug}}">
                    <i class="fas fa-{{icon}} {{status}}"></i>
                    {{text}}
                    </a>
                </li>
            {{/each}}
        </ul>
    </div>
    <nav class="navbar sticky-top navbar-light bg-light">
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation">
        <!--<span class="navbar-toggler-icon"></span>-->
        <img class="shadow-svg"
        src="../public/assets/collapse_burger.svg" 
        alt="triangle with all three sides equal"
        height="auto"
        width="200px" />
        </button>
    </nav>
`);

export const welcome = Handlebars.compile(`
    <div class="jumbotron">
        <h1>Wilkommen!</h1>
        <p>Planungsnavigator App.</p>
    </div>
`);

export const alert = Handlebars.compile(`
    <div class="alert alert-{{type}} alert-dismissible fade show" role="alert">
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
        {{{message}}}
    </div>
`);

export const footer = Handlebars.compile(`
    <footer class="navbar fixed-bottom navbar-light bg-faded">
      <div class="container-fluid">
        <a class="navbar-brand" href="#"><img src='../public/assets/brand_logo.svg' alt="AS Aufzüge" height="auto" width="200px"></a>
        <small id="copyright" class="navbar-text">© AS Aufzüge AG 2020 | Impressum | Allgemeinen Nutzungsbedingungen | Datenschutzerklärung | Cookie-Richtlinie | Version v1.0</small>
        <a class="navbar-brand" href="#"><img src="../public/assets/SMS-Logo.png" alt="Swiss Made" height="auto" width="200px"></a>
      </div>
    </footer>
`);

export const listBuildings = Handlebars.compile(`
    <div class="panel panel-default">
        <div class="panel-heading">Buildings</div>
        {{#if buildings.length}}
            <table class = "table">
                <tr>
                    <th>Building Name</th>
                    <th>Selected</th>
                </tr>
                {{#each buildings}}
                <tr>
                    <td>
                        <p>{{valText}}</p>
                    </td>
                    <td>
                        <button class="btn delete" data-building-id="{{valName}}">{{valSelected}}</button>
                    </td>
                </tr>
                {{/each}}
            </table>
        {{else}}
            <div class ="panel-body">
                <p>No Buildings!<p>
            </div>
        {{/if}}
    </div>
`);